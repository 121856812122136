import { Trans, useTranslation } from 'react-i18next';
import { TextCard } from '../TextCard';
import { ArticleHeading } from '../ArticleHeading';
import { SubchapterCard } from '../SubchapterCard';
import { SectionHeading } from '../SectionHeading';
import { Article } from '../Article';
import { Section } from '../Section';

import subchapter3Handbook1 from '@/assets/handbook/subchapter3-handbook1.png';
import subchapter3Handbook2 from '@/assets/handbook/subchapter3-handbook2.png';
import subchapter3Handbook3 from '@/assets/handbook/subchapter3-handbook3.png';
import subchapter3Handbook4 from '@/assets/handbook/subchapter3-handbook4.png';
import handbookSubchapter2 from '@/assets/handbook/handbook-subchapter2.png';

export const HandbookCompanyIntro = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col gap-12" id={t('companyIntro.title')}>
      <Section>
        <ArticleHeading title={t('companyIntro.title')} />
        <div className="grid grid-cols-3 gap-6">
          {t('companyIntro.subchapters.cards', { returnObjects: true }).map((item, index) => (
            <SubchapterCard key={item + '-' + index} id={item.name} item={item} />
          ))}
        </div>
      </Section>
      <Section id={t('companyIntro.subchapters.story.title')}>
        <div className="px-12">
          <SectionHeading title={t('companyIntro.subchapters.story.title')} align="start" />
          {t('companyIntro.subchapters.story.paragraphs', { returnObjects: true }).map((item, index) => (
            <Trans key={index} components={{ strong: <strong /> }}>
              <p>{item}</p>
            </Trans>
          ))}
        </div>
        <div className="flex flex-wrap justify-center gap-1 pt-2">
          <img
            src={subchapter3Handbook1}
            className="aspect-[4/3] max-h-52 lg:max-h-40"
            alt={t('companyIntro.subchapters.story.title')}
          />
          <img
            src={subchapter3Handbook2}
            className="aspect-[4/3] max-h-52 lg:max-h-40"
            alt={t('companyIntro.subchapters.story.title')}
          />
          <img
            src={subchapter3Handbook3}
            className="aspect-[4/3] max-h-52 lg:max-h-40"
            alt={t('companyIntro.subchapters.story.title')}
          />
          <img
            src={subchapter3Handbook4}
            className="aspect-[4/3] max-h-52 lg:max-h-40"
            alt={t('companyIntro.subchapters.story.title')}
          />
        </div>
      </Section>
      <Section id={t('companyIntro.subchapters.vision.title')}>
        <SectionHeading title={t('companyIntro.subchapters.vision.title')} />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <TextCard>
            <h3 className="text-base font-bold uppercase">{t('companyIntro.subchapters.vision.section1.title')}</h3>
            <p className="text-sm">{t('companyIntro.subchapters.vision.section1.paragraph')}</p>
          </TextCard>
          <TextCard>
            <h3 className="text-base font-bold uppercase">{t('companyIntro.subchapters.vision.section2.title')}</h3>
            <p className="text-sm">{t('companyIntro.subchapters.vision.section2.paragraph')}</p>
          </TextCard>
          <img
            src={handbookSubchapter2}
            className="aspect-video w-full self-center"
            alt={t('companyIntro.subchapters.vision.title')}
          />
          <div className="ml-6 mt-6">
            <h3 className="text-base font-bold uppercase">{t('companyIntro.subchapters.vision.section3.title')}</h3>
            {t('companyIntro.subchapters.vision.section3.paragraphs', { returnObjects: true }).map((item, index) => (
              <p
                key={index}
                className="my-0 [&_#orange]:text-primary [&_#red]:text-[#CE4F4F] [&_#green]:text-[#44A04D] [&_#purple]:text-[#7C70FF]"
              >
                <Trans components={{ span: <span /> }}>{item}</Trans>
              </p>
            ))}
          </div>
        </div>
      </Section>
      <Section id={t('companyIntro.subchapters.expect.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('companyIntro.subchapters.expect.title')} />
          <p className="text-sm">
            <Trans components={{ strong: <strong /> }}>{t('companyIntro.subchapters.expect.paragraph')}</Trans>
          </p>
        </TextCard>
      </Section>
    </Article>
  );
};
